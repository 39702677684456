import Vue from 'vue';

Vue.config.devtools = true;

// style
import "./style.scss";

const App = {};

// custom js
import "./custom.js"

// use
Object.values(require('./use.js').default).forEach(
  i => Vue.use(i)
);

// directives
Object.entries(require('./directives.js').default).forEach(
  e => Vue.directive(e[0], e[1])
);

Object.assign(window, require("./window.js").default);

// components
App.components = {
    ...require('./components.js').default
};

// sandbox
if (process.env.NODE_ENV === "development") {
    const Sandbox = () => import('./Sandbox/src/Sandbox.vue');
    App.render = h => h(Sandbox)
}

new Vue(App).$mount('#app');
